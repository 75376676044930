import { SsccHistory } from '../../../models/History'
import { randomString } from '../../../utils'
import { GroupType, SsccEventType } from '../enum/enum'
import { getElementFromObjectSearchType } from '../func'
import { LineData } from '../table/model'

export function getRef(ssccHistories?: SsccHistory[]) {
  if (!ssccHistories) return ''
  if (ssccHistories.length === 0) return ''
  return ssccHistories[0].reference_number
}

export function getLibelle(ssccHistories?: SsccHistory[]) {
  if (!ssccHistories) return ''
  if (ssccHistories.length === 0) return ''
  return ssccHistories[0].libelle
}

export function getGroup(eventType: SsccEventType): GroupType {
  switch (eventType) {
    case SsccEventType.TYPE_1:
    case SsccEventType.TYPE_4:
    case SsccEventType.TYPE_23:
      return GroupType.Creation
    case SsccEventType.TYPE_2:
      return GroupType.Suppression

    case SsccEventType.TYPE_5:
    case SsccEventType.TYPE_6:
    case SsccEventType.TYPE_11:
      return GroupType.Entree

    case SsccEventType.TYPE_8:
    case SsccEventType.TYPE_10:
    case SsccEventType.TYPE_12:
    case SsccEventType.TYPE_13:
    case SsccEventType.TYPE_24:
    case SsccEventType.TYPE_25_1:
      return GroupType.Sortie
    case SsccEventType.TYPE_25_2:
      return GroupType.Sortie
    case SsccEventType.TYPE_26:
      return GroupType.Edition
  }

  return GroupType.NULL
}

export function mapTypeToEvent(ssccHistory: SsccHistory): {
  event: string
  value: string
} {
  const {
    event_type,
    location_address = '',
    qte = '',
    object_number = '',
    reference_number = '',
    description,
  } = ssccHistory
  const group = getGroup(event_type)
  switch (event_type) {
    case SsccEventType.TYPE_1:
      return {
        event: `${group}: renseignement de la SSCC en attendu`,
        value: object_number,
      }

    case SsccEventType.TYPE_2:
      return {
        event: `${group}: enlèvement de la SSCC en attendu`,
        value: object_number,
      }

    case SsccEventType.TYPE_4:
      return {
        event: `${group}: génération de SSCC pour attendu`,
        value: object_number,
      }
    case SsccEventType.TYPE_5:
      return {
        event: `${group}: sur attendu (${object_number}) à l'emplacement (${location_address})`,
        value: '',
      }
    case SsccEventType.TYPE_6:
      return {
        event: `${group}: par mouvement manuel à l'emplacement`,
        value: location_address,
      }
    case SsccEventType.TYPE_8:
      return {
        event: `${group}: par mouvement manuel à l'emplacement)`,
        value: location_address,
      }
    case SsccEventType.TYPE_10:
      return {
        event: `${group}: par régularisation négative`,
        value: `${qte} carton${Number(qte) > 1 ? 's' : ''}`,
      }
    case SsccEventType.TYPE_11:
      return {
        event: `${group}: par régularisation positive`,
        value: `${qte} carton${Number(qte) > 1 ? 's' : ''}`,
      }
    case SsccEventType.TYPE_12:
      return { event: `${group}: par réappropicking informatique`, value: '' }
    case SsccEventType.TYPE_13:
      return {
        event: `${group}: par réappropicking au stock durant mission`,
        value: description,
      }
    case SsccEventType.TYPE_24:
      return {
        event: `${group}: préparé pour commande`,
        value: `(${object_number}) durant mission (n°${description})`,
      }
    case SsccEventType.TYPE_25_1:
      return {
        event: `${group}: chargé pour commande`,
        value: `(${object_number}) durant mission (n°${description})`,
      }
    case SsccEventType.TYPE_25_2:
      return {
        event: `${group}: chargé (Réf: ${reference_number}) pour commande`,
        value: `(${object_number}) durant mission (n°${description})`,
      }
    case SsccEventType.TYPE_26:
      return { event: `${group}: échange palette`, value: object_number }

    case SsccEventType.TYPE_23:
      return {
        event: `${group}: Ajout ${qte} carton${
          Number(qte) > 1 ? '(s)' : ''
        } (Réf: ${reference_number}) pour commande (${object_number}) durant mission (n°${description})`,
        value: '',
      }
  }

  return { event: '', value: '' }
}

export function buildLines(
  ssccHistories: SsccHistory[],
  fromGeneral?: boolean
): LineData[] {
  const result: LineData[] = []

  ssccHistories.forEach((ssccHistory) => {
    const data = [
      { name: 'chrono', value: ssccHistory.chrono },
      { name: 'date', value: String(ssccHistory.created_at * 1000) },
      { name: 'heure', value: String(ssccHistory.created_at * 1000) },
      { name: 'utilisateur', value: ssccHistory.user_name || '' },
      { name: 'evenement', value: mapTypeToEvent(ssccHistory).event },
      { name: 'value', value: mapTypeToEvent(ssccHistory).value },
      { name: 'object_number', value: ssccHistory.object_number || '' },
      {
        name: 'object_search_type',
        value: getElementFromObjectSearchType(
          ssccHistory.object_search_type || ''
        ),
      },
    ]
    if (fromGeneral) {
      data.push({
        name: 'object_number_or_sscc',
        value: ssccHistory.sscc || '',
      })
      data.push({ name: 'object_type', value: 'SSCC' })
    }

    result.push({
      id: randomString(16),
      data: data,
    })
  })

  return result
}
