import dayjs from 'dayjs'
import { Unit } from '../../../enum/Unit'
import { AttenduHistory, SsccHistory } from '../../../models/History'
import { AttenduEventType, GroupType, SsccEventType } from '../enum/enum'
import { LineData } from '../table/model'
import {
  getElementFromObjectSearchType,
  getElementFromObjectType,
} from '../func'
import { randomString } from '../../../utils/random'

export function getGroup(eventType: AttenduEventType): GroupType {
  switch (eventType) {
    case AttenduEventType.A1:
      return GroupType.Creation
    case AttenduEventType.A2:
      return GroupType.Suppression
    case AttenduEventType.A3456:
    case AttenduEventType.A3:
    case AttenduEventType.A4:
    case AttenduEventType.A5:
    case AttenduEventType.A6:
    case AttenduEventType.A7:
    case AttenduEventType.A8:
      return GroupType.Edition
    case AttenduEventType.A9_1:
    case AttenduEventType.A9_2:
    case AttenduEventType.A10:
      return GroupType.Entree
  }

  return GroupType.NULL
}

const splitString = '_-_'

function getValues(values: string) {
  if (values.includes(splitString)) {
    // const newValues = values.substring(0, values.length - 3);
    const valueList = values.split(splitString)
    return valueList
  }

  return []
}

function mapFieldToLabel(field: string): string {
  if (field.includes('[') && field.includes(']')) {
    if (field.includes('AttProduction') && field.includes('TotalPieses'))
      return 'Total pièces'
    if (field.includes('AttProduction') && field.includes('Quantite'))
      return 'Qte'

    if (field.includes('AttProduction') && field.includes('SousQte'))
      return 'Sous-Qte'

    if (field.includes('AttProduction') && field.includes('DLUO')) return 'DLUO'
    if (field.includes('AttProduction') && field.includes('ReferenceNumber'))
      return 'Référence'
    if (
      field.includes('AttProduction') &&
      field.includes('RefFournisseurNumber')
    )
      return 'Réf fournisseur'
    if (field.includes('AttProduction') && field.includes('Libelle'))
      return 'Libellé'
    if (field.includes('AttProduction') && field.includes('Lot')) return 'Lot'
    if (field.includes('AttProduction') && field.includes('Conditionement'))
      return 'Cdn'
    if (field.includes('AttProduction') && field.includes('Sscc')) return 'SSCC'
  }

  switch (field) {
    case 'AttCommentaire.Content':
      return 'Commentaire'

    case 'NumberOfFournisseur':
      return 'N° Fournisseur'

    case 'NoCommande':
      return 'N° Commande'

    case 'Priorite':
      return 'Priorité'

    case 'Livraison':
      return 'Livraison'

    case 'Commande':
      return 'Commande'

    case 'AttFournisseur.CodeNom':
      return 'Code & Nom Fournisseur'

    case 'Photos':
      return 'Photo(s)'
  }

  return ''
}

export function mapTypeToEvent(
  attenduHistory: AttenduHistory
): { event: string; value: string }[] {
  const {
    event_type = AttenduEventType.TYPE_NULL,
    changes = [],
    object_number = '',
  } = attenduHistory
  const group = getGroup(event_type)

  switch (event_type) {
    case AttenduEventType.A1:
      return [{ event: `Création Attendu`, value: object_number }]
    case AttenduEventType.A2:
      return [{ event: `Suppression Attendu`, value: object_number }]
    case AttenduEventType.A3456:
      let final: { event: string; value: string }[] = []
      changes.forEach((change) => {
        const changeEventType = change.event_type
        const { field = '', value } = change
        let { old_value, new_value } = change

        const label = mapFieldToLabel(field)

        if (
          field === 'Livraison' ||
          field === 'Commande' ||
          (field.includes('AttProduction') && field.includes('DLUO'))
        ) {
          if (old_value !== '0' && old_value)
            old_value = dayjs(Number(old_value) * 1000).format('DD/MM/YYYY')
          if (new_value !== '0' && new_value)
            new_value = dayjs(Number(new_value) * 1000).format('DD/MM/YYYY')
        }

        if (changeEventType === AttenduEventType.A3) {
          if (field.includes('AttProduction')) {
            const [refName, lot, sscc, dluo, cdn, qte] = getValues(value || '')
            const dLot = displayValue('Lot', lot)
            const dSscc = displayValue('SSCC', sscc)
            const dDluo = displayValue('DLUO', dluo)
            const dCdn = displayValue('Cdn', cdn)
            const dQte = displayValue('Qte', qte)
            const listText = [
              `Edition ${label} Réf(${refName})`,
              dLot,
              dSscc,
              dDluo,
              dCdn,
              dQte,
            ].filter((item) => item)
            final.push({
              event: listText.join(', ') + ``,
              value: `${old_value} \u279E ${new_value}`,
            })
          }

          // else if(field  === 'Livraison' || field === 'Commande'){
          //     final.push(`Edition ${label}: ${dayjs(Number(old_value) * 1000).format('DD/MM/YYYY')} \u279E ${dayjs(Number(new_value) * 1000).format('DD/MM/YYYY')}`);
          // }
          else
            final.push({
              event: `Edition ${label}`,
              value: `${old_value} \u279E ${new_value}`,
            })
        }
        if (changeEventType === AttenduEventType.A4) {
          if (field.includes('AttProduction')) {
            const [refName, lot, sscc, dluo, cdn, qte] = getValues(value || '')
            const dLot = displayValue('Lot', lot)
            const dSscc = displayValue('SSCC', sscc)
            const dDluo = displayValue('DLUO', dluo)
            const dCdn = displayValue('Cdn', cdn)
            const dQte = displayValue('Qte', qte)
            let listText = []
            if (new_value === '0' && field.includes('DLUO')) {
              listText = [
                `Edition ${label} Réf(${refName})`,
                dLot,
                dSscc,
                dDluo,
                dCdn,
                dQte,
              ].filter((item) => item)
              final.push({
                event: listText.join(', '),
                value: `${getSymbol(old_value)} \u279E Non renseigné`,
              })
            } else if (old_value === '0' && field.includes('DLUO')) {
              listText = [
                `Renseignement ${label} Réf(${refName})`,
                dLot,
                dSscc,
                dDluo,
                dCdn,
                dQte,
              ].filter((item) => item)
              final.push({
                event: listText.join(', '),
                value: getSymbol(new_value),
              })
            } else if (old_value) {
              listText = [
                `Edition ${label} Réf(${refName})`,
                dLot,
                dSscc,
                dDluo,
                dCdn,
                dQte,
              ].filter((item) => item)
              final.push({
                event: listText.join(', '),
                value: `${getSymbol(old_value)} \u279E ${getSymbol(new_value)}`,
              })
            } else {
              listText = [
                `Renseignement ${label} Réf(${refName})`,
                dLot,
                dSscc,
                dDluo,
                dCdn,
                dQte,
              ].filter((item) => item)
              final.push({
                event: listText.join(', '),
                value: getSymbol(new_value),
              })
            }
          } else if (field === 'Livraison' || field === 'Commande') {
            if (old_value === '0')
              final.push({
                event: `Renseignement ${label}`,
                value: new_value || '',
              })
            if (new_value === '0')
              final.push({
                event: `Edition ${label}`,
                value: `${old_value} \u279E Non renseigné`,
              })
          } else {
            if (!old_value)
              final.push({
                event: `Renseignement ${label}`,
                value: new_value || '',
              })
            if (!new_value)
              final.push({
                event: `Edition ${label}`,
                value: `${old_value} \u279E Non renseigné`,
              })
          }
        }
        if (changeEventType === AttenduEventType.A5) {
          if (field.includes('AttProduction')) {
            const [refName, lot, sscc, dluo, cdn, qte] = getValues(value || '')
            const dLot = displayValue('Lot', lot)
            const dSscc = displayValue('SSCC', sscc)
            const dDluo = displayValue('DLUO', dluo)
            const dCdn = displayValue('Cdn', cdn)
            const dQte = displayValue('Qte', qte)
            const listText = [
              `Suppression Réf(${refName})`,
              dLot,
              dSscc,
              dDluo,
              dCdn,
              dQte,
            ].filter((item) => item)
            final.push({ event: listText.join(', '), value: '' })
          }
          if (field === 'AttFile') {
            const newValue = (value || '_-__-_')
              .split(splitString)
              .join('')
              .trim()
            final.push({ event: `Suppression document`, value: newValue || '' })
          }
        }
        if (changeEventType === AttenduEventType.A6) {
          if (field.includes('AttProduction')) {
            const [refName, lot, sscc, dluo, cdn, qte] = getValues(value || '')
            const dLot = displayValue('Lot', lot)
            const dSscc = displayValue('SSCC', sscc)
            const dDluo = displayValue('DLUO', dluo)
            const dCdn = displayValue('Cdn', cdn)
            const dQte = displayValue('Qte', qte)
            const listText = [
              `Réf(${refName})`,
              dLot,
              dSscc,
              dDluo,
              dCdn,
              dQte,
            ].filter((item) => item)
            final.push({
              event: 'Renseignement Réf',
              value: listText.join(', '),
            })
          } else if (field === 'AttFile') {
            const newValue = (value || '_-__-_')
              .split(splitString)
              .join('')
              .trim()
            final.push({ event: `Ajout document`, value: newValue || '' })
          } else if (field === 'AttFournisseur.CodeNom')
            final.push({
              event: `Renseignement Fournisseur`,
              value: new_value || '',
            })
          else final.push({ event: `Renseignement`, value: new_value || '' })
        }
      })
      return final

    case AttenduEventType.A8:
      return [{ event: `Envoi en mission Attendu`, value: object_number }]
    case AttenduEventType.A9_1:
      return [
        { event: `Entrée sur Attendu (${object_number}) à quai`, value: '' },
      ]
    case AttenduEventType.A9_2:
      return [
        { event: `Entrée sur Attendu (${object_number}) au stock`, value: '' },
      ]

    case AttenduEventType.A10:
      return [
        { event: `Entrée sur Attendu (${object_number}) au stock`, value: '' },
      ]
  }
  return [{ event: '', value: '' }]
}

export function buildLines(attenduHistories: AttenduHistory[]): LineData[] {
  const result: LineData[] = []

  attenduHistories.forEach((attenduHistory, idx) => {
    const eventWithValues = mapTypeToEvent(attenduHistory)
    const {
      created_at = 0,
      user_name = '',
      chrono = '',
      event_type = AttenduEventType.TYPE_NULL,
      object_type = '',
      object_number = '',
      object_search_type = '',
    } = attenduHistory
    const group = getGroup(event_type)
    const mainLine: LineData = {
      id: randomString(16),
      data: [
        { name: 'chrono', value: chrono },
        { name: 'date', value: String(created_at * 1000) },
        { name: 'heure', value: String(created_at * 1000) },
        { name: 'utilisateur', value: user_name },
        { name: 'object_type', value: getElementFromObjectType(object_type) },
        { name: 'object_number_or_sscc', value: object_number },
        {
          name: 'object_search_type',
          value: getElementFromObjectSearchType(object_search_type || ''),
        },
      ],
    }
    result.push(mainLine)

    if (eventWithValues.length === 1) {
      let evenementValue = `${group}: ${eventWithValues[0].event}`
      if (evenementValue.includes('Edition: Edition')) {
        const evenementValueSplit = evenementValue.split('Edition: Edition')
        evenementValue = 'Edition: ' + evenementValueSplit[1]
      }
      if (evenementValue.includes('Création: Création')) {
        const evenementValueSplit = evenementValue.split('Création: Création')
        evenementValue = 'Création: ' + evenementValueSplit[1]
      }
      if (evenementValue.includes('Suppression: Suppression')) {
        const evenementValueSplit = evenementValue.split(
          'Suppression: Suppression'
        )
        evenementValue = 'Suppression: ' + evenementValueSplit[1]
      }
      mainLine.data.push({ name: 'evenement', value: evenementValue })
      mainLine.data.push({
        name: 'value',
        value: `${eventWithValues[0].value}`,
      })
    } else {
      mainLine.data.push({ name: 'evenement', value: `${group}` })
      mainLine.childrenLineId = []

      eventWithValues.forEach((eventWithValue, idx) => {
        const childId = randomString(16)
        if (mainLine.childrenLineId) mainLine.childrenLineId.push(childId)

        const childLine: LineData = {
          id: childId,
          parentLineId: mainLine.id,
          data: [
            { name: 'chrono', value: chrono },
            { name: 'date', value: String(created_at * 1000) },
            { name: 'heure', value: String(created_at * 1000) },
            { name: 'utilisateur', value: user_name },
            { name: 'evenement', value: eventWithValue.event },
            { name: 'value', value: eventWithValue.value },

            {
              name: 'object_type',
              value: getElementFromObjectType(object_type),
            },
            { name: 'object_number_or_sscc', value: object_number },
            {
              name: 'object_search_type',
              value: getElementFromObjectSearchType(object_search_type || ''),
            },
          ],
        }
        result.push(childLine)
      })
    }
  })

  return result
}

function getSymbol(value: string | undefined, unit?: Unit) {
  if (value) return `${value}${unit || ''}`
  return 'Non renseigné'
}

function displayValue(name: string, value: any) {
  if (!value) return

  switch (name) {
    case 'Lot':
    case 'Cdn':
    case 'Qte':
      return `${name}(${value})`
    case 'SSCC':
      return `${name}(${value})`
    case 'DLUO':
      if (value === '0') return ''
      return `${name}(${dayjs(Number(value) * 1000).format('DD/MM/YYYY')})`
  }

  return ''
}
