import dayjs from 'dayjs'
import Define from '../../../constants/define'
import {
  AttenduHistory,
  CommandeHistory,
  ReferenceForHistory,
  SsccHistory,
} from '../../../models/History'
import { HistoryRequest } from '../../../models/request/HistoryRequest'
import { buildLines as buildLinesAttendu } from '../attendu/func'
import { buildLines } from '../sscc/func'
import { buildLines as buildLinesRef } from '../reference/func'
import { LineData } from '../table/model'
import { buildLines as buildLinesCommande } from '../commande/func'

export function buildGeneralLines(
  data: (AttenduHistory | ReferenceForHistory | SsccHistory)[]
): LineData[] {
  let result: LineData[] = []
  data.forEach((line) => {
    if (line.object_search_type === 'attendu') {
      result = result.concat(buildLinesAttendu([line as AttenduHistory]))
    } else if (line.object_search_type === 'reference') {
      result = result.concat(buildLinesRef([line as ReferenceForHistory]))
    } else if (line.object_search_type === 'sscc') {
      result = result.concat(buildLines([line as SsccHistory], true))
    } else if (line.object_search_type === 'commande') {
      result = result.concat(buildLinesCommande([line as CommandeHistory]))
    }
  })
  return result
}

export function convertSearchParamToHistoryRequest(
  input: Record<string, any>
): HistoryRequest {
  const request: HistoryRequest = {
    offset: 0,
    limit: 0,
  }

  const { localClientCodenom, localCompanyNom, localWarehouseNom } =
    getLocalThreeSome()
  request.client_code_nom = localClientCodenom || ''
  request.warehouse_code_nom = localWarehouseNom || ''
  request.company_code_nom = localCompanyNom || ''
  if (input['from']) request.from = dayjs(input['from']).startOf('day').unix()
  if (input['to']) request.to = dayjs(input['to']).endOf('day').unix()

  if (input['chrono']) request.chrono = input['chrono']
  if (input['user_ids']) request.user_ids = input['user_ids'].join(',')
  if (input['object_number']) request.object_number = input['object_number']
  if (input['object_search_types'])
    request.object_search_types = input['object_search_types']
      .map(
        (object_search_type: Record<string, any>) => object_search_type.value
      )
      .join(',')
  if (input['types'])
    request.types = input['types']
      .map((type: Record<string, any>) => type.value)
      .join(',')
  if (input['actions'])
    request.actions = input['actions']
      .map((action: Record<string, any>) => action.value)
      .join(',')

  return request
}

export function getLocalThreeSome() {
  const localClientCodenom =
    localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || ''
  const localCompanyNom =
    localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM) || ''
  const localWarehouseNom =
    localStorage.getItem(Define.CHOOSING_WAREHOUSE_CODENOM) || ''

  return { localClientCodenom, localCompanyNom, localWarehouseNom }
}
