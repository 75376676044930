// prettier-ignore
import { Col, DatePicker, Empty, Pagination, Row, Spin } from 'antd'
import React, { Key, useCallback, useEffect, useState } from 'react'
import HistoryTable from '../table/HistoryTable'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { LineData } from '../table/model'
import { Attendu } from '../../../models'
import attenduApi from '../../../http/attenduApi'
import historyApi from '../../../http/historyApi'
import dayjs from 'dayjs'
import { disabledDateAfter, disabledDateBefore, MinDayForSearch } from '../func'
import {
  AttenduHistoriesResponse,
  AttenduHistory,
  ReferenceForHistory,
  SsccHistory,
} from '../../../models/History'
import { buildLines } from '../attendu/func'
import { RootState } from '../../../app/store'
import { useDispatch, useSelector } from 'react-redux'
import ResearchBar from '../../stock/components/ResearchBar/ResearchBar'
import { ModalName } from '../../stock/modal/customModalSlice'
import {
  clearList,
  getAllStock,
  getTotalStock,
} from '../../../redux/reducers/stockSlice'
import {
  buildGeneralLines,
  convertSearchParamToHistoryRequest,
  getLocalThreeSome,
} from './func'
import { PagingEnum } from '../../../enum/PagingEnum'
import { getCurrentFiltersText } from '../../../utils/format'
import hourglass from '../../../assets/icons/hourglass.svg'
import { HistoryRequest } from '../../../models/request/HistoryRequest'
import { cloneDeep } from 'lodash'
import { t } from 'i18next'

enum Source {
  FROM = 'FROM',
  TO = 'TO',
}

export default function HistoryGeneral() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { id } = useParams()
  const [rangeTimestamp, setRageTimestamp] = useState({
    from: 0,
    to: 0,
  })
  const [searchParams, setSearchParam] = useSearchParams()
  const pageIndex = Number(searchParams.get('page-index') || 0)
  const pageSize = Number(searchParams.get('page-size') || 0)
  const [histories, setHistories] = useState<{
    entry?: (AttenduHistory | ReferenceForHistory | SsccHistory)[]
    messageError?: string
    metadata?: {
      count?: number
      limit?: number
      offset?: number
      total?: number
      count_history?: number
    }
  }>({})

  const [params, setParams] = useState<HistoryRequest>({
    offset: pageIndex - 1,
    limit: pageSize,
  })

  const [loadingData, setLoadingData] = useState<boolean>(false)

  // ResearchBar
  const [isLoadMoreAll, setLoadMoreAll] = useState(false)
  const [isCollapsible, setIsCollapsible] = useState<boolean>(false)
  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [fetchedStockParents, setFetchedStockParents] = useState<
    Array<{
      stockParentId: string
      currentAmount: number
    }>
  >([])
  const { choosingClient, choosingCompany, choosingWarehouse } = useSelector(
    (state: RootState) => state.account
  )
  const isChooseWarehouse =
    choosingClient && choosingWarehouse && choosingCompany

  //footer
  const [isShowLess, setIsShowLess] = useState<boolean>(false)

  const onPickTime = (source: Source, value: dayjs.Dayjs | null) => {
    if (source === Source.FROM)
      setRageTimestamp({
        ...rangeTimestamp,
        from: value?.startOf('day').unix() || 0,
      })
    else
      setRageTimestamp({
        ...rangeTimestamp,
        to: value?.endOf('day').unix() || 0,
      })
  }

  const onDisabledFrom = (date: dayjs.Dayjs) => {
    const min = dayjs(MinDayForSearch).startOf('day')
    const max =
      rangeTimestamp.to === 0
        ? dayjs().endOf('day')
        : dayjs.unix(rangeTimestamp.to)
    return disabledDateBefore(min, date) || disabledDateAfter(max, date)
  }

  const onDisabledTo = (date: dayjs.Dayjs) => {
    const min =
      rangeTimestamp.from === 0
        ? dayjs(MinDayForSearch).startOf('day')
        : dayjs.unix(rangeTimestamp.from)
    const max = dayjs().endOf('day')

    return disabledDateBefore(min, date) || disabledDateAfter(max, date)
  }

  const searchStock = useCallback(
    (values: Record<string, any>) => {
      const { localClientCodenom } = getLocalThreeSome()
      if (!localClientCodenom) {
        setHistories({})
        return
      }
      setSearchParam({ 'page-index': '1', 'page-size': '25' })
      const params = convertSearchParamToHistoryRequest(values)
      params.offset = pageIndex - 1
      params.limit = pageSize
      setParams(params)
    },
    [dispatch, navigate, pageIndex, pageSize]
  )
  const onSort = (name: string) => {
    if (!params) return

    const paramsClone = cloneDeep(params)

    paramsClone.order_by = name
    paramsClone.order = 1
    setParams(paramsClone)

    // getData(paramsClone)
  }
  const onRemoveSort = (name: string) => {
    if (!params) return
    const paramsClone = cloneDeep(params)
    delete paramsClone.order
    delete paramsClone.order_by
    setParams(paramsClone)
    // getData(paramsClone)
  }

  const getData = (params: HistoryRequest) => {
    setLoadingData(true)
    const sortBy = params.order_by
    if (sortBy === 'utilisateur') {
      delete params.order_by
      delete params.order
    }
    if (sortBy === 'date') params.order_by = 'created_at'
    historyApi
      .getGeneralHistories(params)
      .then((res) => {
        if (sortBy === 'utilisateur') {
          res.entry = res.entry?.sort((a, b) => {
            let aValue = ''
            let bValue = ''
            if (a.object_search_type === 'reference') aValue = a.user_id || ''
            else aValue = a.user_name || ''
            if (b.object_search_type === 'reference') bValue = b.user_id || ''
            else bValue = b.user_name || ''
            return aValue.localeCompare(bValue, undefined, {
              numeric: true,
              sensitivity: 'base',
            })
          })
        }
        setHistories(res)
      })
      .finally(() => {
        setLoadingData(false)
      })
  }

  useEffect(() => {
    if (!pageSize || !pageIndex) {
      setSearchParam({
        'page-index': `1`,
        'page-size': `25`,
      })

      return
    }
    const { localClientCodenom, localCompanyNom, localWarehouseNom } =
      getLocalThreeSome()
    if (!localClientCodenom) return

    const newParams: HistoryRequest = {
      ...params,
      offset: Number(pageIndex) - 1,
      limit: Number(pageSize),
      client_code_nom: localClientCodenom,
      warehouse_code_nom: localWarehouseNom,
      company_code_nom: localCompanyNom,
    }
    getData(newParams)
  }, [params])

  return (
    <div className="flex flex-col  h-[calc(100vh-4rem)] w-screen">
      <div className="flex-1 overflow-auto">
        <div className="flex flex-col overflow-hidden w-full h-full">
          <div>
            <ResearchBar
              screen={ModalName.STOCK}
              onSearch={searchStock}
              listFilters={['Evènement', 'Type']}
              requiredToAdd={['client']}
              requiredToFilters={['client', 'company', 'warehouse']}
              isCollapsible={isCollapsible}
              setIsCollapsible={setIsCollapsible}
              searchBarHeight={12}
              // totalIn={isChooseWarehouse ? metadata?.total : 0}
              // total={isChooseWarehouse ? totalListStock?.number : 0}
              totalIn={histories.metadata?.total || 0}
              total={histories.metadata?.count_history || 0}
              useForHistory={true}
            />
          </div>

          <div className="h-6"></div>

          <div className="flex-1 flex flex-col  px-10 overflow-auto ">
            <Row gutter={14}>
              <Col>
                <img src={hourglass} alt="" />
              </Col>
              <Col>
                <span className="text-[#20458F] font-semibold	text-2xl">
                  HISTORIQUE
                </span>
              </Col>
            </Row>
            <div className="h-5"></div>
            <div className="flex-grow bg-[#FBFBFB] flex-1 overflow-auto pr-2">
              <Spin spinning={loadingData}>
                <HistoryTable
                  empty={
                    <span>
                      <Empty
                        description={''}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                      <p className="text-center">
                        {choosingClient && choosingWarehouse && choosingCompany
                          ? t('noStockFound')
                          : t('notEnoughCondition')}
                      </p>
                    </span>
                  }
                  sorts={[
                    {
                      name: 'chrono',
                      onSort: onSort,
                      onRemoveSort: onRemoveSort,
                    },
                    {
                      name: 'date',
                      onSort: onSort,
                      onRemoveSort: onRemoveSort,
                    },

                    {
                      name: 'utilisateur',
                      onSort: onSort,
                      onRemoveSort: onRemoveSort,
                    },

                    {
                      name: 'sscc',
                      onSort: onSort,
                      onRemoveSort: onRemoveSort,
                    },
                    {
                      name: 'object_search_type',
                      onSort: onSort,
                      onRemoveSort: onRemoveSort,
                    },
                  ]}
                  forGeneral
                  initData={buildGeneralLines(histories.entry || [])}
                />
              </Spin>
            </div>
            {/*
                <Row className='py-2 px-4 box-border'>
                    <Col>
                        <Pagination
                            className='attendu-history-paging'
                            showSizeChanger={false}
                            total={historyResponse?.data.metadata.total}
                            current={pageIndex + 1}
                            onChange={onChangePagination}
                            pageSize={pageSize}
                        />
                    </Col>
                    <Col></Col>
                </Row> */}
          </div>
          {histories.entry && histories.entry?.length > 0 && choosingClient && (
            <Pagination
              style={{ position: 'inherit' }}
              className="custom-pagination stock-pagination mt-4"
              locale={{ items_per_page: '' }}
              total={histories.metadata?.total || 0}
              defaultPageSize={PagingEnum.DEFAULT_PAGE_SIZE}
              showLessItems={isShowLess}
              pageSizeOptions={[
                PagingEnum.DEFAULT_PAGE_SIZE,
                PagingEnum.PAGE_SIZE_1,
                PagingEnum.PAGE_SIZE_2,
              ]}
              pageSize={Number(pageSize)}
              current={Number(pageIndex)}
              showSizeChanger
              showTotal={(total, range) =>
                `${range[0] >= 0 ? range[0] : 0}-${
                  range[1] >= 0 ? range[1] : 0
                } sur ${total}`
              }
              onChange={(page, size) => {
                setParams({
                  ...params,
                  offset: page - 1,
                  limit: size,
                })
                setSearchParam({
                  'page-index': `${page}`,
                  'page-size': `${size}`,
                })
                // navigate(
                //     `/gestion-de-stock/stock?page-index=${page}&page-size=${size}${getCurrentFiltersText()}`
                // )
                setIsShowLess(() => {
                  return page > 4
                })
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}
